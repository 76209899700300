@tailwind base;
@tailwind components;
@tailwind utilities;

ol,
ul,
menu {
    list-style: initial;
}

body {
    overflow: hidden;
}
body * {
    font-family: Roboto !important;
}

.jpFont *,
.jpFont {
    font-family: 'Roboto', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', Osaka, 'メイリオ', Meiryo,
        'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif !important;
}

body .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
}
body .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
    height: 100%;
}
body .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
    height: 100%;
    font-weight: normal;
    font-size: 0.875rem;
    border: none;
}
body .react-datepicker-wrapper .react-datepicker__input-container input:focus {
    outline: none !important;
}
body .react-datepicker-wrapper .react-datepicker__close-icon::after {
    cursor: pointer;
    color: #78778b;
    background-color: transparent;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '\00d7';
}
body #root .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 15px;
    transform: translate3d(0px, 17px, 0px) !important;
}
body #root .react-datepicker-popper[data-placement^='top'] {
    margin-top: 8px;
    transform: translate3d(0px, -284px, 0px) !important;
}
body .react-datepicker {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    border: none;
}
body .react-datepicker .react-datepicker__triangle {
    display: none;
}
body
    .react-datepicker
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 95px !important;
}
body .react-datepicker .react-datepicker__header {
    background: white !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
body .react-datepicker .react-datepicker__header .react-datepicker__current-month,
body .react-datepicker .react-datepicker__header .react-datepicker-time__header {
    font-size: 0.875rem;
    color: #404040 !important;
    font-weight: 500 !important;
}
body .react-datepicker .react-datepicker__day.react-datepicker__day--in-selecting-range {
    background-color: #ebebf6 !important;
}
body .react-datepicker .react-datepicker__day--keyboard-selected {
    background: none;
}
body .react-datepicker .react-datepicker__day--in-range {
    background-color: #ebebf6 !important;
}
body .react-datepicker .react-datepicker__day--selected,
body .react-datepicker .\.react-datepicker__day--selected,
body .react-datepicker .react-datepicker__time-list-item--selected,
body .react-datepicker .react-datepicker__day--range-start,
body .react-datepicker .react-datepicker__day--range-end,
body .react-datepicker .react-datepicker__day--keyboard-selected.react-datepicker__day--selecting-range-start,
body .react-datepicker .react-datepicker__day--keyboard-selected.react-datepicker__day--selecting-range-end {
    background: #7869b9 !important;
    color: white !important;
}
body .react-datepicker .react-datepicker__day--today {
    background: #e1dae8 !important;
    color: #404040;
    font-weight: normal;
}
body .react-datepicker .react-datepicker__time-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
}
body .react-datepicker .react-datepicker__day {
    color: #404040;
}
body .react-datepicker .react-datepicker__day.react-datepicker__day--disabled {
    color: #ccc;
}
body .react-datepicker .react-datepicker__time-container--with-today-button {
    right: -85px !important;
    border: none;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}
body .react-datepicker .react-datepicker__today-button {
    background: white !important;
    font-size: 14;
    font-weight: normal;
    font-family: Roboto;
    padding: 10px 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}
body .react-datepicker .react-datepicker__day-names .react-datepicker__day-name {
    font-weight: 500 !important;
}

body .react-datepicker .react-datepicker__day {
    border-radius: 50% !important;
}

body .react-datepicker .react-datepicker__day:hover {
    background-color: #e4e1f1 !important;
}

.datepicker .right-cell .react-datepicker-popper {
    margin-left: -125px;
    margin-top: 20px !important;
}

body .DateRangePicker_picker {
    z-index: 100;
    font-family: 'Roboto';
}

.CalendarDay__default {
    border: none;
}

.CalendarDay__default:hover {
    border: none;
    background: #ebebf6;
    color: #2a293a;
    border-radius: 4px;
}

.CalendarDay__selected_span {
    background: #ebebf6;
    color: #2a293a;
    border: none;
}

.CalendarDay__selected_span:hover {
    background: #ebebf6;
    color: #2a293a;
    border: none;
    opacity: 0.5;
}

.CalendarDay__selected {
    background: #7869b9;
    color: white;
    border-radius: 4px;
    font-weight: bold;
}

.CalendarDay__selected:hover {
    background: #7869b9;
    color: white;
    opacity: 0.5;
    border: none;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #ebebf6;
    color: #2a293a;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__selected_end {
    border: none;
}

.DateRangePicker {
    border: none;
    border-radius: 4px;
    background: #f7f8fa;
}

.DateRangePickerInput {
    background: #f7f8fa;
    border: none;
    border-radius: 4px;
}

.DateInput {
    background: transparent;
}

.DateInput_input__focused {
    border-bottom: 1px solid #7869b9;
}

.DateInput_input {
    padding: 6px 11px 6px;
    font-size: 15px;
    font-family: 'Roboto';
    color: #2a293a;
    background: transparent;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
}

.child-link-highlight a {
    color: #2d97f2;
}

.editor * {
    line-height: 22px;
    word-break: break-word;
    word-wrap: break-word;
}

.editor p {
    margin-top: 0;
    margin-bottom: 0;
    min-height: 22;
}

.editor blockquote {
    margin: 0px 0px 14px;
    padding: 12px 24px;
    border-left: 2px solid #93929e;
    border-radius: 4px;
    background: #f7f8fa;
    line-height: 22px;
}

.editor ul,
.editor ol {
    margin-top: 0;
    margin-bottom: 14px;
    border-radius: 4px;
}

.editor ul li,
.editor ol li {
    margin-bottom: 3px;
}

:root {
    --ui-selecting: #e0effd;
    --grid-background: #f7f8fa;
    --colors-light-grey: #dbdce1;
    --colors-light-grey-blue: #acadb9;
    --colors-lavender: #4d3f8a;
    --colors-dark-lavender: #392b76;
    --colors-light-lavender: #7869b9;
    --colors-pale-lavender: #a69fc4;
    --colors-solitude: #ebebf6;
    --colors-pale-navy: #f3f4fc;
    --colors-code-header: #787791;
    --colors-bright-red: #eb4f5e;
    --colors-burnt-slena: #ea6a46;
    --colors-crusta: #f9772a;
    --colors-sun: #f8ad13;
    --colors-atlantis: #77b32b;
    --colors-sham-rock: #2dd2a3;
    --colors-light-cyan: #5dcde3;
    --colors-dodger-blue: #2d97f2;
    --colors-fun-blue: #1d66ae;
    --colors-ship-cove: #6a7ab9;
    --colors-fuchsia-blue: #6e5bbe;
    --colors-froly: #f67b8e;
    --colors-darkness: #606066;
    --colors-system-icon: #6c75c5;
    --colors-hawkes-blue: #e0effd;
    --colors-cherub: #fbe5e7;
    --colors-light-sun: #fbd17b;
    --colors-granny-apple: #e0f8f1;
    --colors-pinka: #fab7bc;
    --colors-griptide: #96e8d1;
    --colors-highlight: #4a91e2;
    --colors-border: #e9eaef;
    --colors-hover: #f7f8fa;
    --colors-resize: #57acf5;
    --colors-token: #6a7ab9;
}

.scrollbar::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

.window .scrollbar::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}

.grid-cell.ui-selected {
    background: var(--ui-selecting);
}

.grid-cell.ui-selecting {
    background: var(--ui-selecting);
}

.grid-cell.footer {
    background-color: white !important;
}

.grid-cell.header {
    background-color: #f4f5f7;
    /* font-weight: bold; */
}

.grid-cell.cell-selected {
    background-color: #f4f5f7 !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.cell .highlight {
    background: #f8ad13;
    color: white;
}

.cell .highlight-tb {
    background: rgba(45, 151, 242, 0.25);
}

.cell .highlight-token,
.cell .highlight-tb-token {
    background: #6a7ab9;
    border-radius: 2px;
    font-weight: 500;
    color: white;
}

.cell .highlight-token-new,
.cell .highlight-tb-token-new {
    background: #ef727e;
    border-radius: 2px;
    font-weight: 500;
    color: white;
}

.cell .highlight-zeroWidthSpace {
    background: #f8ad14;
    border-radius: 2px;
}

.cell .highlight-zeroWidthSpace::before {
    content: '•';
}

.cell .highlight-zeroWidthSpace-new {
    background: #f8ad14;
    border-radius: 2px;
}

.cell .highlight-zeroWidthSpace-new::before {
    content: '•';
}

.cell .showUserName {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    top: 0;
    right: 0;
    font-size: 8;
    width: auto;
    font-weight: bold;
    padding-left: 3px;
    padding-right: 3px;
    height: 12;
    color: white;
    border-bottom-left-radius: 2px;
}

.cell:hover .cell .showUserName {
    opacity: 1;
}

.ReactVirtualized__Grid__innerScrollContainer {
    overflow: unset !important;
}

.simplebar .simplebar-track {
    z-index: 100;
    pointer-events: auto;
}

.simplebar .simplebar-track.simplebar-horizontal .simplebar-scrollbar.window-chrome {
    height: 11px !important;
    top: 0 !important;
}

.simplebar .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    top: 2px !important;
}

.simplebar .simplebar-track.simplebar-vertical .simplebar-scrollbar.window-chrome {
    width: 11px !important;
}

.simplebar .simplebar-track.simplebar-vertical .simplebar-scrollbar.window-chrome::before {
    width: 100%;
    left: 0;
}

.simplebar .simplebar-scrollbar::before {
    background-color: #d1d5da;
    opacity: 1;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    width: 9px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 9px;
}

.simplebar .simplebar-scrollbar:hover {
    cursor: pointer;
}

.simplebar .simplebar-content-wrapper.window-chrome::-webkit-scrollbar-thumb {
    background: transparent;
}

.dialog {
    z-index: 1301 !important;
}

.dialog .MuiDialog-paper {
    background: rgba(0, 0, 0, 0.2);
}

.revert-style,
.revert-style * {
    all: revert;
}

.single-revert-style {
    all: revert;
}

@layer components {
    .grid-cell {
        @apply w-full h-full border-r border-b box-border border-grey-border bg-white overflow-hidden;
    }

    .disabled {
        @apply opacity-40 pointer-events-none;
    }

    .scrollbar-app {
        @apply scrollbar scrollbar-thumb-rounded-full  scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-300;
    }

    .body2 {
        @apply prose prose-sm max-w-full;
    }

    .body1 {
        @apply prose prose-sm font-medium;
    }

    .caption {
        @apply prose prose-sm text-grey-blue text-[0.8125rem];
    }

    .text-light {
        @apply prose prose-sm text-white text-sm;
    }

    .text-error {
        @apply prose prose-sm text-brightRed;
    }

    .btn {
        @apply relative flex font-medium py-2 px-3.5 justify-center items-center rounded cursor-pointer text-sm shadow-none;
    }

    .btn-contained-primary {
        @apply text-white  bg-lavender-light hover:bg-lavender-500 focus:outline-none focus:ring-highlight focus:ring-[2px] focus:bg-lavender-light;
    }

    .btn-contained-secondary {
        @apply text-white  bg-lightError hover:bg-hoverError focus:outline-none focus:ring-highlight focus:ring-[2px] focus:bg-lightError;
    }

    .btn-contained-disabled {
        @apply !bg-grey-light text-white pointer-events-none !cursor-default focus:ring-0;
    }

    .btn-outlined {
        @apply text-text-primary border border-silver  bg-white hover:bg-navy-pale hover:border-lavender-pale focus:outline-none focus:ring-highlight focus:ring-[2px] focus:bg-white;
    }

    .btn-outlined-disabled {
        @apply !bg-white  text-grey-disabled pointer-events-none !cursor-default;
    }

    .btn-normal {
        @apply text-lavender-light border border-transparent bg-white hover:bg-navy-pale hover:border-lavender-pale focus:outline-none focus:ring-highlight focus:ring-[2px] focus:bg-white;
    }

    .btn-disabled {
        @apply !bg-white text-grey-disabled pointer-events-none !cursor-default;
    }

    .number-step {
        @apply w-[18px] h-[18px] flex items-center justify-center bg-lavender-light text-white rounded-full text-[12px] mr-2 font-medium;
    }

    .tag {
        @apply max-w-[200px] truncate py-0 px-1 rounded border;
    }

    .tag-sm {
        @apply h-5;
    }

    h1 {
        @apply text-text-primary text-4xl font-medium not-italic;
    }

    h2 {
        @apply text-text-primary text-3xl font-medium not-italic;
    }

    h3 {
        @apply text-text-primary text-2xl font-medium not-italic;
    }

    h4 {
        @apply text-text-primary text-lg font-medium not-italic;
    }
}

.MuiTabs-flexContainer {
    height: 100%;
}
